.main-container {
    background-image: url(https://cdn.onpay.my/users/lenoracreative/media/uploads/PEJABAT%20TRUST%20GCT.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.main-form {
    opacity: 1;
}

.main-card {
    padding: 20px;
    display: flex;
}

.main-title {
    text-align: center;
    font-size: 14px;
}

.main-title img {
    max-width: 30vh;
}

.main-package-name {
    text-align: center;
}

.main-name-container {
    display: flex;
    flex-direction: row;
}

.main-salutation {
    width: 45%;
}

.main-button-container {
    text-align: center;
}

.main-button-send {
    background-color: #25d366;
    border-color: #25d366;
    font-weight: bold;
    font-size: 1.5em;
    width: 100%;
}

.main-alert {
    color: red;
}